import React from 'react'
import Features from '../Features/Features';
import './Deatails.css'

const Details = () => {
    return (
     
                
				<div className="home-container">
					<h2 className="main-heading1 lite text-center " >Entira Works To Keep You Well,</h2>
					<h2 className="custom-heading text-center"> With A Full Range Of Vital Services.</h2>
					{/* <p>Entira Clinics are all about people maintaining a healthy outlook, 
                        both as individuals who live in or near our community and as employees in its successful businesses. 
                        After all, peace of mind and wellness are central to good work performance.
                    </p>

					<p>Your employer has made an Entira Clinic available because they believe you are a valuable asset, and need to stay well as
                         often as possible for the common good. It’s reassuring to know Entira is just minutes from your work area, staffed by trusted physicians and other 
                        professionals from the community to 
                        provide an entire spectrum of affordable medical services you deserve:
                        
                    </p> */}
                        {/* <ul>
                            <li>Wellness and athletic physicals, and preventative guidance</li>
                            <li>Primary care services</li>
                            <li>Minor urgent care</li>
                            <li>Lab Studies</li>
                            <li>Occupational health matters</li>
                        </ul> */}
                        <div className="container main-container container-fixed" >
                            <Features />
                        </div>
                        
                    {/* <p>
                        If there is a need for referral to additional care—such as medical specialists,
                        nutritionists, fitness experts or other healthcare providers—our professionals know who best to recommend from the community.
                    </p>
                    <p>
                        Discover how the convenience and comprehensive services of Entira can benefit you and your family,
                        in sickness and in health. Visit your Entira Clinic or our website to learn more. We're here to ensure that you’re always at your best.
                    </p> */}
				</div>
		
            
      
    )
}

export default Details
