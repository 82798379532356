import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { WebLink } from '../../../Export/Links';
import Doctors from '../Doctors';
import {DoctorsData} from '../DoctorsData';
import CustomModal from './Modal/Modal'

const axios = require('axios');


const SliderSection = ({SliderNumber}) => {

    const [DoctorData,setDoctorData] = useState([]);
    const [SSlider,setSSlider] = useState(4);
    const [Team,setTeam] = useState([]);

    useEffect( ()=> {
        console.log(WebLink);
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmZTJlMzhiNWVlZTMxNWU4Yzc3NmQ2MyIsImlhdCI6MTYwODc0NDUzMX0.T8Ck96I4qaFnwwwDm_OVJ6Vv2i29ozG5PkssfrWH1t0"
        axios.get(WebLink+"/Doctor",{headers:{token:token}} ).then(function (response) {
            //  if(response.data.status == 403) window.location = "/";
            //  setDoctorData(response.data)
            setTeam(response.data)
             console.log(response);
            
            return response.data
          })
          .catch(function (error) {
            
            console.log(error)
          })
        },[]);
    
  

    const Sliders = ()=>{
		if (window.innerWidth <= 960) setSSlider(2)
		else if(window.innerWidth <= 477) setSSlider(1)
		else setSSlider(4)
	}
    window.addEventListener('resize',Sliders);


    var MA;
    const filter = (array) =>{
        let MA = [] ;
        let i=0; let track =0;let sum=0;let end = false
        while( i < array.length){
            let SA = {sliderClass:"item", rowClass:"row", des:[]};
            for (let j = 0; j < SSlider; j++) {

                SA.des.push(array[sum]);
                sum += 1 ;
                if (sum == array.length ) end = true;
                if(end) {
                    MA.push(SA);
                    return MA
                }
            }
            MA.push(SA);
            if(end) return MA
            i++;
        }
        return MA
    }

    useEffect(()=>{
        if(Team !== []) filter(Team);
	}, [SliderNumber])
   

    return (
        <>
            {(filter(Team)).map((item,index)=>{
                return(
                    <div key={index} className={index == 0 ? "item active" : "item"}> 
                        <div className="row">
                            {item.des.map((detail,i)=>{
                                return (
                                    <div className="col-md-3 col-sm-6 col-xs-6" key={"col"+i}>
										<div className="news-post-box" style={{height:'530px'}}>
											<img src={WebLink+"/Image/"+detail.ImageFileName} alt="Latest News" className="img-responsive img-center-sm img-center-xs ImageConfig" />
											<div className="inner">
												<h5><Link to="#">{detail.DoctorName}{detail.Qualification.trim() != ""? ",":""} {detail.Qualification}</Link></h5>
												<h6><Link to="#">{detail.Designation}</Link></h6>
												<p>{detail.ParaOne.split(" ").slice(0,12).join(" ")}....</p>
												
                                                <CustomModal title={detail.DoctorName+", "+detail.Qualification} item={detail} />
                                               
											</div>
										</div>
									</div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            <div >

            </div>

        </>
    )
}

export default SliderSection
