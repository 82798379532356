import React,{useState} from 'react';
import { Modal, Button, DatePicker,Input,Select } from 'antd';
import "./Poster.css"
import { BBILogo, EntiraLogo } from '.';

const Poster = () => {
    const [isModalVisible, setIsModalVisible] = useState(true);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        console.log("handleok")
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        
        // let video = document.getElementById("Youtube_video_1");
        // video.contentWindow.postMessage( '{"event":"command", "func":"pauseVideo", "args":""}', '*');
        
        setIsModalVisible(false);

    };

  return (
    <Modal  open={isModalVisible}  onOk={handleOk} onCancel={handleCancel} style={{minWidth:'50%'}} footer={null}>
        
        <div className="" style={{padding:"0",margin:'0'}}>
        </div>
        <div className="news-post-single" style={{marginTop:"-20px"}} >
            
            <div className="inner" >
            <div style={{textAlign:"center",marginBottom:"20px"}}>
            {/* <h1>JOIN <span style={{color:"green"}}>US!</span> </h1> */}
            </div>
            <article className=""> 
                                <img src={EntiraLogo} alt="Single Department" className="img-responsive img-center-sm img-center-xs" />
                                
                            </article>  

            </div>

        </div>
        <hr className='modal-hr'/>
        <div className='poster-margin'></div>
        <p style={{ fontSize: "2rem" }}>Use convenient <strong>TeleConnect</strong> appointments to keep healthy! All your covered dependents through the Brookshire Brothers health plan or the Entira Clinic Card can use this service as well. Just call for an appointment today: <span style={{ color: "rgba(27,127,204,.8);" }}>936-634-1400</span></p>
        <div className='poster-margin'></div>
        <hr className='modal-hr'/>
        <div className='poster-margin'></div>
        <article className="bbi-logo"> 
                                <img src={BBILogo} width={350} alt="Single Department" className=" bbi-logo img-responsive img-center-sm img-center-xs" />
                                </article>
        {/* <div style={{textAlign:"center"}}>
           <h4>
                <i className="fa fa-calendar-day" style={{color:"#585858",marginRight:"10px"}}></i>
                <span style={{color:"green"}}>7th September, 2022</span> 
                
            </h4>
           <h4>
                <i className="fa fa-clock" style={{color:"#585858",marginRight:"10px"}}></i>
                11:00 am – 12:30 pm
                </h4>
            
            <div style={{paddingBottom:"20px"}}></div>
           
        </div>
        <div className='text-center'> 
        
        <a className="req-btn"  href="https://bit.ly/3SYoJ7E" target="_blank" rel="noopener noreferrer">Reserve your online attendance</a>
        </div>
        <div className="container345" id='videos_watch' style={{textAlign:"center"}}>
        <iframe id="Youtube_video_1" width="100%" height="500" src={"https://www.youtube.com/embed/vGHloyMI_jY"+"?enablejsapi=1&version=3&playerapiid=ytplayer"} title="Entira | Women's Health Talk - Powered by Katherine Diggs, MSN, BSN, APRN-C | Health Disease" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div> */}
        
    </Modal>
  )
}

export default Poster