import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {NewsData} from './NewsData';
import './News.css';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';

import './CustomModal/Modal.css'
import { WebLink } from '../../Export/Links';

const News = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [Details,setDetails] = useState({});

    const [News,setNews] = useState([]);

    useEffect( ()=> {
        // console.log(WebLink);
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmZTJlMzhiNWVlZTMxNWU4Yzc3NmQ2MyIsImlhdCI6MTYwODc0NDUzMX0.T8Ck96I4qaFnwwwDm_OVJ6Vv2i29ozG5PkssfrWH1t0";
        axios.get(WebLink+"/News",{headers:{token:token}} ).then(function (response) {
            //  if(response.data.status == 403) window.location = "/";
            //  setDoctorData(response.data)
            setNews(response.data);
            // console.log(response);
            
            return response.data
          })
          .catch(function (error) {
            
            console.log(error)
          })
        },[]);

    const showModal = (details) => {
        setDetails(details);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const DateParse=(date)=>{
        let d = new Date(date);
        return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()
      }
    return (
        <div>
            <div className="custom">
                <div className="container345">
                    <div className="main-news-img">
                        
                        <img className="img-design" src={News[News.length -1 ] != undefined ? WebLink+"/Image/"+News[News.length-1].ImageFileName:""} alt="Snow" style={{width:'100%',height:'400px'}} />
                        <span className="img-bottom-text news_span_text" ><span>{News[News.length -1 ] != undefined ? News[News.length-1].Section: null}</span>, 
                        <span  > {News[News.length -1 ] != undefined ? DateParse(News[News.length-1].dateAdded) :"12/12/1912"  }</span><br/>
                        <span style={{'cursor':'pointer',"backgroundColor":"transparent"}}><a className='pdfLink'  target="_blank" href={News[News.length -1 ] != undefined ? WebLink+"/Image/"+News[News.length-1].newsPdf:""}>Download PDF</a></span>
                        </span>
                        
                    </div>

                    <div className="centered-text ">
                        <span className="news-text ">{News[News.length -1 ] != undefined ? News[News.length-1].Heading:null}</span><br/> 
                        
                    </div>
                </div>
                {/* {console.log( News[News.length -1 ] != undefined )} */}

               {News.map((item,index)=>{
                  return (index === News.length-1 ? 
                    // if true
                    null : 
                    // if false
                        (<div className="col-md-6 col-sm-12 col-xs-12 " onClick={()=>showModal(item)}  key={index}>
                        <div className="custom-row">
                            <div className="column img-section">
                                <img src={WebLink+"/Image/"+item.ImageFileName} alt="newsImage" style={{height:'100px',width:'100px'}} />
                            </div>
                            <div className="column divCol">
                                <div className="divText-1"><p>{item.Heading}</p></div>
                                <div className="divText-2">{item.Section}</div>
                                {/* <div className="divText-2"></div> */}
                                <div className="divText-3">{DateParse(item.dateAdded)}, <a target="_blank" href={ WebLink+"/Image/"+item.newsPdf} style={{"cursor":"pointer","color":"#1890ff"}}>Download PDF</a></div>
                            </div>
                        </div>
                    </div>))
               })}
            </div>

            <div id="news_modal">
            <Modal title={Details.Section+", "+DateParse(Details.dateAdded)} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} style={{minWidth:'90%'}}
             footer={null}
            >          
                    <div className="container main-container" style={{padding:"0",margin:'0'}}>
                        <div className="row col_margin">
                            <div className="col-md-11 col-sm-12 col-xs-12 " >
                            <div className="news-post-single" style={{minWidth:'80%'}}>
                                <article className="news-post">
                                <img src={WebLink+"/Image/"+Details.ImageFileName} alt="Single Department" className="img-responsive img-center-sm img-center-xs img_margin" style={{width:'300px',height:'300px'}} />
                                    
                                    <div className="inner">
                                        <h4>{Details.Heading}</h4>
                                        <h6><i>{DateParse(Details.dateAdded)}</i>, <i>{Details.Section}</i> </h6>
                                        <span style={{'cursor':'pointer',"backgroundColor":"transparent",'color':"blue"}}><a target="_blank" href={WebLink+"/Image/"+Details.newsPdf}>Download PDF</a></span>
                                        
                                        {/* <div className="news-post-content doctor-info" >
                                           
                                            
                                        </div>  */}
                                    </div>
                                </article>
                            </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
       
    )
}

export default News
