exports.FeatureData = [
    {
        Title:"Preventative Guidance",
        Description:"Entira Clinic provides you the guidance to avoid the occurrence of disease either through eliminating disease agents or increasing resistance to disease.",
        Link:"",
        IconClass:"fa fa-hand-holding-heart"
    },
    {
        Title:"Primary Care Services",
        Description:"Entira Clinic provides a wide range of primary care including health promotion, disease prevention, health maintenance, and diagnosis and treatment of acute and chronic illnesses.",
        Link:"",
        IconClass:"fa fa-stethoscope"
    },
    {
        Title:"Minor Urgent Care",
        Description:"Entira Clinic is equipped to handle serious illness or injury that needs immediate attention but is not a life-threatening event.",
        Link:"",
        IconClass:"fa fa-ambulance"
    },
    {
        Title:"Lab Studies And Tests",
        Description:"Entira Clinic has the ability to order blood samples to be drawn, for testing and diagnosing purposes, at no additional cost to the patient.",
        Link:"",
        IconClass:"fa fa-flask"
    },
    {
        Title:"Occupational Health Matters",
        Description:"Entira Clinic promotes and maintains the physical, mental and social well-being of the employees of Brookshire Brothers.",
        Link:"",
        IconClass:"fa fa-comment-medical"
    },
    {
        Title:"Women's Health Care",
        Description:"Entira Clinic strives to make a valuable difference in the women’s healthcare arena by making it incredibly easy for female patients to consult with, and be medically evaluated by, a highly regarded Women’s Health and Wellness Specialist, Katherine Diggs MSN, NP-C.",
        Link:"",
        IconClass:"fa fa-venus"
    },
    {
        Title:"Registered Dietitian",
        Description:"Good health, and disease prevention, starts with a healthy diet and lifestyle which is why Entira Clinic offers consultations with our extremely knowledgeable dietitian, Amy Wright.",
        Link:"",
        IconClass:"fa fa-user-md"
    },
    {
        Title:"Tele Medicine",
        Description:"Entira Clinic strives to meet your convenience and safety needs by offering TeleMedicine consultations with our medical experts for basic office visits.",
        Link:"",
        IconClass:"fa fa-comment-medical"
    }
    
]