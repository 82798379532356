import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Modal, Button, DatePicker,Input,Select } from 'antd';
import {WebLink} from '../../Export/Links'
import axios from 'axios';
import 'antd/dist/antd.css';
import './Footer.css';
import './Modal.css';



const { Option } = Select;
const dateFormat = 'MM/DD/YYYY';
const Appointment = () => {
    // Modal 
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // End of Modal 

    const [items, setItems ] = useState("");
    const [errors, setErrors ] = useState("");
    const massageLength = 120;
    const massageWarningLength = 80;

    const handleErrors = (name,value)=>{
        if(!value) setErrors(errors => ({...errors, [name]:"Please provide a value"}));
        else setErrors(errors => ({...errors, [name]:""}));
    }

    const handleInputChange = (event) => {
        event.persist();
        // console.log("event",event)
        setItems(items => ({...items, [event.target.name]: event.target.value}));

        if(event.target.name == "message"){
            // console.log( parseInt(massageLength - String(event.target.value).length))
            if(String(event.target.value).length > massageWarningLength) setErrors(errors => ({...errors, "message":`Characters left ${parseInt(massageLength - String(event.target.value).length)}`}));
            else setErrors(errors => ({...errors, "message":""}));
        } 

        if(event.target.name != "message") handleErrors(event.target.name,event.target.value) ;
    }

    const handleDateChange = (date, dateString, name)=> {
        // console.log(date, dateString); console.log(name);
        let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        if(name == "dob") {
            setItems(items => ({...items, "dob": dateString}));
            if(dateString)setErrors(errors => ({...errors, "scheduleDate":"" }));
            else setErrors(errors => ({...errors, "scheduleDate":"Please provide your Date of Birth"}));
        }
        if(name == "scheduleDate") {
            // console.log(dateString);
            setItems(items => ({...items, "scheduleDate": dateString}));
            if(dateString){
                let day = new Date(dateString);
                day = days[day.getDay()];
                if(day == 'saturday' || day == 'sunday' ) setErrors(errors => ({...errors, "scheduleDate":"We are close in Sunday and Saturday" }));
                else setErrors(errors => ({...errors, "scheduleDate":""}));
            }else setErrors(errors => ({...errors, "scheduleDate":"Please select a day expect Sunday and Saturday"}));
        }
        
    }

    const handleSubmit = (event)=>{
        if(event){
            event.preventDefault();
        }
        console.log("last",items,errors,checkErrors(items,['message']));
        if(checkErrors(items,['message'])){
            console.log("last",items,errors);
        }else return alert("Form error found",errors);

        // api call
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmZTJlMzhiNWVlZTMxNWU4Yzc3NmQ2MyIsImlhdCI6MTYwODc0NDUzMX0.T8Ck96I4qaFnwwwDm_OVJ6Vv2i29ozG5PkssfrWH1t0"
        let res = axios.post(WebLink+"/appt",items,{headers:{token:token}} ).then(function (response) {
            //  if(response.data.status == 403) window.location = "/";
             console.log(response.data);
             if(response.data.status == 200){
                setItems("")
                setIsModalVisible(false);
                 return alert("Appointment submitted successfully")
             }else{
                 return alert("Something wrong, try again!")
             }
             
            return response.data
          })
          .catch(function (error) {
            return alert("Something wrong, try again!")
            console.log(error)
          })

        
    }

    const checkErrors = (field,except) =>{
        // let verify = true;
        let noError = 0;
        if(errors[except[0]]) errors[except[0]] = "";

        // name
        if(field.name){
            if(field.name == "") {noError ++; setErrors(errors => ({...errors, "name":"Type your name" }));}
            else setErrors(errors => ({...errors, "name":"" })); 
        }else {noError++;setErrors(errors => ({...errors, "name":"Type your name" })); }
        // console.log("name",noError)
        //dob
        if(field.dob){
            if(field.dob == "") {noError++; setErrors(errors => ({...errors, "dob":"Select your DOB" }));}
            else setErrors(errors => ({...errors, "dob":"" }));
        }else {noError ++;setErrors(errors => ({...errors, "dob":"Select your DOB" })); }
        // console.log("dob",noError)
        // email
        if(field.email){
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            // console.log(!(regex.test(field.email)))
            if(field.email == "") {noError ++;setErrors(errors => ({...errors, "email":"Type your email" }));}
            else setErrors(errors => ({...errors, "email":"" })); 
            if(!(regex.test(field.email))) {noError ++; setErrors(errors => ({...errors, "email":"Email format is not correct" }));}
        }else {noError ++; setErrors(errors => ({...errors, "email":"Type your email" })); }
        // console.log("email",noError)
        // phone number
        if(field.phoneNumber){
            field.phoneNumber = field.phoneNumber.replace(/ /g,'') ;
            // let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{7,7}$/im;
            // console.log(!(regex.test(field.phoneNumber)))
            if(field.phoneNumber == "") {noError ++;setErrors(errors => ({...errors, "phoneNumber":"Type your phone number. example[+1 1234567890]" }));}
            else  setErrors(errors => ({...errors, "phoneNumber":"" })); 
            if(((field.phoneNumber).length != 12) && ((field.phoneNumber).length != 10) ) {noError ++; setErrors(errors => ({...errors, "phoneNumber":"Phone number format is [+1 1234567890]" }));}
        }else {noError ++;setErrors(errors => ({...errors, "phoneNumber":"Type your phone number" }));}
        // console.log("phoneNumber",noError)
        // schedule Date
        if(field.scheduleDate){
            if(field.scheduleDate == "") {noError ++; setErrors(errors => ({...errors, "scheduleDate":"Select your schedule date" }));}
            else setErrors(errors => ({...errors, "scheduleDate":"" })); 
        }else {noError ++;setErrors(errors => ({...errors, "scheduleDate":"Select your schedule date" })); }
        // console.log("scheduleDate",noError)
        // schedule Time
        if(field.scheduleTime){
            if(field.scheduleTime == "") {noError ++;setErrors(errors => ({...errors, "scheduleTime":"Select your schedule time" }));}
            else setErrors(errors => ({...errors, "scheduleTime":"" })); 
        }else {noError ++;setErrors(errors => ({...errors, "scheduleTime":"Select your schedule time" })); }
        // console.log("scheduleTime",noError)
        // message
        if(field.message){
            if(String(field.message).length > massageLength ) {noError ++;setErrors(errors => ({...errors, "message":`Massage text should be between ${massageLength} characters` }));} 
            else  setErrors(errors => ({...errors, "message":"" })); 
        }
        // console.log("message",noError)
        if(noError == 0) return true;
        else return false;
    }



  return (
  <>
    
    <div  className="wrap appt-a">
		    <button onClick={showModal} className='button-a button-b' title="Make an appointment"  >
                <i className="fa fa-calendar-check-o"></i>
            </button>
	    </div>
        <Modal  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} style={{minWidth:'35%'}} footer={null}>
          
            <div className="" style={{padding:"0",margin:'0'}}>

            <div className="news-post-single" style={{paddingTop:"30px",marginBottom:"-20px"}} >
                        <article className="news-post" >
                        
                            
                            <div className="inner" >
                                        <h4 style={{textAlign:"center",color:"#0B4575"}}>Request For an Appointment</h4>
                                        {/* <h6><i>{item.Designation}</i></h6> */}
                                        <div className="news-post-content" >
                                            <form autoComplete="off">
                                                <div className='form-div'>
                                                    <p className='form-title'>Name:</p>
                                                    <Input className="form-input" placeholder="My name" onInput={handleInputChange} name='name' value={items.name} />
                                                    <p className='form-error'>{errors.name}</p>
                                                </div>

                                                <div className='form-div'>
                                                    <p className='form-title'>DOB:</p>
                                                    <DatePicker className="form-input" placeholder='12/12/2001' onChange={(date, dateString) => handleDateChange(date, dateString, "dob")} format={dateFormat} />
                                                    <p className='form-error'>{errors.dob}</p>
                                                </div>

                                                <div className='form-div'>
                                                    <p className='form-title'>E-mail:</p>
                                                    <Input className="form-input" placeholder="example@example.com" onPasteCapture={handleInputChange} onInput={handleInputChange} name='email' value={items.email} />
                                                    <p className='form-error'>{errors.email}</p>
                                                </div>

                                                <div className='form-div'>
                                                    <p className='form-title'>Phone:</p>
                                                    <Input className="form-input" placeholder="+1 1234567890" max="12" onPaste={handleInputChange} onInput={handleInputChange} name='phoneNumber' value={items.phoneNumber} />
                                                    <p className='form-error'>{errors.phoneNumber}</p>
                                                </div>

                                                <div className='form-div'>
                                                    <p className='form-title'>Requested Date:</p>
                                                    <DatePicker className="form-input" placeholder='12/12/2001' format={dateFormat} onChange={(date, dateString) => handleDateChange(date, dateString, "scheduleDate")} />
                                                    <p className='form-error'>{errors.scheduleDate}</p>
                                                </div>

                                                <div className='form-div'>
                                                    <p className='form-title'>Preferred Time Slot:</p>
                                                    <select className="form-input ant-input" onInput={handleInputChange} name="scheduleTime" value={items.scheduleTime}>
                                                        <option value=""> Select a time slot</option>
                                                        
                                                        <option value="08:00-09:00">08:00 am - 09:00 am</option>
                                                        <option value="10:00-11:00">10:00 am - 11:00 am</option>
                                                        <option value="11:00-12:00">11:00 am - 12:00 pm</option>

                                                        <option value="13:00-14:00">01:00 pm - 02:00 pm</option>
                                                        <option value="14:00-15:00">02:00 pm - 03:00 pm</option>
                                                        <option value="15:00-16:00">03:00 pm - 04:00 pm</option>
                                                        <option value="16:00-17:00">04:00 pm - 05:00 pm</option>
                                                    </select>
                                                    <p className='form-error'>{errors.scheduleTime}</p>
                                                </div>

                                                <div className='form-div'>
                                                    <p className='form-title'>Message (If any):</p>
                                                    <Input className="form-input" placeholder="Type your message [optional]" onInput={handleInputChange} name='message' maxLength='10' value={items.message} />
                                                    <p className='form-error'>{errors.message}</p>
                                                </div>

                                                <div className='form-div'>
                                                <button className="submit-btn" type='submit' onClick={handleSubmit} title="Make an appointment">Submit Request</button>
                                                </div>
                                            </form>
                                        </div>
                                        
                                    </div>
                        </article>
                    </div>
               
            </div>
        </Modal>
    </>
  );
};

export default Appointment;
