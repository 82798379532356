exports.DoctorsData = [
    {
        id:"01",
        Name:"Dr. Rick Martin, D.O., FAAFP",
        Status:"Medical Director, Entira - Lufkin",
        ImgLink:"assets/images/doctor-profile-2.jpg",
        ParaOne:`Dr. Martin serves as medical director and an Entira physician providing onsite care for clients and overseeing nurse practitioners.`,
        ParaTwo:`Dr. Martin is a graduate of North Texas State University Texas College of Osteopathic
         Medicine (1981), and completed his Family Medical residency at UT Medical Branch in Galveston, Texas (1984).`,
        ParaThree:`He has been Chairman of the Board of Woodland Heights Medical Center since 1997 (to present) and was Chief of Staff for Woodland Heights (1989-90).
         Since 2004 he has served as the medical director of the Wound Healing Center at Woodland Heights and is Board Certified with Diplomat 
        status by the American Board of Family Practice (1984). Dr. Martin is also a Board Certified Fellow of the American Academy of Family Practice (1989).`,
        ParaFour:`During the last 15 years, Dr. Martin has also held Chairman or Board level positions with the following organizations: 
        Physicians of East Texas, Triad Hospitals, and the Angelina County Medical Society. His commitment to East Texas spans three decades.`
    },
    {
        id:"02",
        Name:"Netha Thompson, FNP",
        Status:"Nurse Practitioner",
        ImgLink:"assets/images/doctor-profile-1.jpg",
        ParaOne:`Ms. Thompson serves as our full-time nurse practitioner with Entira providing onsite care for clients and daily oversight of all clinic operations.`,
        ParaTwo:`Ms. Thompson is a graduate of Stephen F. Austin State University with a Bachelor of Science 
        in Nursing (1993). She completed her Master of Science Nursing degree at UT Medical Branch in Galveston, Texas (2004).`,
        ParaThree:`Her professional certifications include: Advanced Cardiac Life Support (ACLS), Certification Emergency Nursing (CEN), Family Nurse Practitioner 
        (FNP) through the American Academy of Nurse Practitioners.
         Ms. Thompson is a past instructor for ACLS and Trauma Nursing Core Curriculum. She has also earned certification for Advanced Burn Life Support.`,
        ParaFour:`Her extensive emergency, trauma, and intensive care experience gives her an appreciation for the preventative approach at Entira.
         She looks forward to helping patients live healthier, balanced lives so they can enjoy life to the fullest.`
    },
    {
        id:"03",
        Name:"Netha Thompson, FNP",
        Status:"Nurse Practitioner",
        ImgLink:"assets/images/doctor-profile-1.jpg",
        ParaOne:`Ms. Thompson serves as our full-time nurse practitioner with Entira providing onsite care for clients and daily oversight of all clinic operations.`,
        ParaTwo:`Ms. Thompson is a graduate of Stephen F. Austin State University with a Bachelor of Science 
        in Nursing (1993). She completed her Master of Science Nursing degree at UT Medical Branch in Galveston, Texas (2004).`,
        ParaThree:`Her professional certifications include: Advanced Cardiac Life Support (ACLS), Certification Emergency Nursing (CEN), Family Nurse Practitioner 
        (FNP) through the American Academy of Nurse Practitioners.
         Ms. Thompson is a past instructor for ACLS and Trauma Nursing Core Curriculum. She has also earned certification for Advanced Burn Life Support.`,
        ParaFour:`Her extensive emergency, trauma, and intensive care experience gives her an appreciation for the preventative approach at Entira.
         She looks forward to helping patients live healthier, balanced lives so they can enjoy life to the fullest.`
    },
    {
        id:"04",
        Name:"Netha Thompson, FNP",
        Status:"Nurse Practitioner",
        ImgLink:"assets/images/doctor-profile-1.jpg",
        ParaOne:`Ms. Thompson serves as our full-time nurse practitioner with Entira providing onsite care for clients and daily oversight of all clinic operations.`,
        ParaTwo:`Ms. Thompson is a graduate of Stephen F. Austin State University with a Bachelor of Science 
        in Nursing (1993). She completed her Master of Science Nursing degree at UT Medical Branch in Galveston, Texas (2004).`,
        ParaThree:`Her professional certifications include: Advanced Cardiac Life Support (ACLS), Certification Emergency Nursing (CEN), Family Nurse Practitioner 
        (FNP) through the American Academy of Nurse Practitioners.
         Ms. Thompson is a past instructor for ACLS and Trauma Nursing Core Curriculum. She has also earned certification for Advanced Burn Life Support.`,
        ParaFour:`Her extensive emergency, trauma, and intensive care experience gives her an appreciation for the preventative approach at Entira.
         She looks forward to helping patients live healthier, balanced lives so they can enjoy life to the fullest.`
    },
    {
        id:"05",
        Name:"Fifth Member",
        Status:"Nurse Practitioner",
        ImgLink:"assets/images/doctor-profile-1.jpg",
        ParaOne:`Ms. Thompson serves as our full-time nurse practitioner with Entira providing onsite care for clients and daily oversight of all clinic operations.`,
        ParaTwo:`Ms. Thompson is a graduate of Stephen F. Austin State University with a Bachelor of Science 
        in Nursing (1993). She completed her Master of Science Nursing degree at UT Medical Branch in Galveston, Texas (2004).`,
        ParaThree:`Her professional certifications include: Advanced Cardiac Life Support (ACLS), Certification Emergency Nursing (CEN), Family Nurse Practitioner 
        (FNP) through the American Academy of Nurse Practitioners.
         Ms. Thompson is a past instructor for ACLS and Trauma Nursing Core Curriculum. She has also earned certification for Advanced Burn Life Support.`,
        ParaFour:`Her extensive emergency, trauma, and intensive care experience gives her an appreciation for the preventative approach at Entira.
         She looks forward to helping patients live healthier, balanced lives so they can enjoy life to the fullest.`
    }
]