import React from 'react';
import './App.css';
import { BrowserRouter  as Router,Switch,Route} from 'react-router-dom';

import Slider from './Components/Slider/Slider';
import TopSection from './Components/TopSection/TopSection';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import MeetingForm from './Components/MeetingForm/MeetingForm';
import Poster from './Components/Home/Poster/Poster';

function App() {
  return (
    <Router >
      <TopSection/>
      <Switch>
        <Route path='/meetingForm' exact component={MeetingForm} />

        <Route path='/' exact >
          <Poster />
          <Slider />
          <Home />
      </Route>
      </Switch>
      
      
      <Footer />

      
    </Router>
  );
}

export default App;
