import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';


import SliderSection from './SliderSection';




const AllDoctors = () => {

	const [Doctor,setDoctor] = useState([]);
	const [Slider,setSlider] = useState(4);
	const [Item, setItem] = useState('');

	const SliderNumber = ()=>{
		if (window.innerWidth <= 960) setSlider(1)
		else if(window.innerWidth <= 477) setSlider(0)
		else setSlider(3)
	}

	useEffect(()=>{
		SliderNumber();
	}, [])

	window.addEventListener('resize',SliderNumber);

	

    return (
        // <!-- Start Latest News Area -->
			<section >
				<div className="main-block1">
					{/* <!-- Start News Carousel --> */}
					<div id="news-carousel" className="news-carousel carousel slide" data-ride="carousel"  >
						<div className="carousel-inner">

							< SliderSection SliderNumber={Slider} />
							

							
						</div>
						
						<Link className="left carousel-control" to="#news-carousel" role="button" data-slide="prev">
							<span className="fa fa-angle-left" aria-hidden="true"></span>
							<span className="sr-only">Previous</span>
						</Link>
						<Link className="right carousel-control" to="#news-carousel" role="button" data-slide="next">
							<span className="fa fa-angle-right" aria-hidden="true"></span>
							<span className="sr-only">Next</span>
						</Link>
						
					</div>
					
				</div>
			</section>
    )
}

export default AllDoctors
