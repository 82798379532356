import React,{useState,useEffect} from 'react'
import axios from 'axios';

import { WebLink } from '../../Export/Links'
const Videos = () => {
    const [video,setVideo] = useState('');
    useEffect( ()=> {
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmZTJlMzhiNWVlZTMxNWU4Yzc3NmQ2MyIsImlhdCI6MTYwODc0NDUzMX0.T8Ck96I4qaFnwwwDm_OVJ6Vv2i29ozG5PkssfrWH1t0";
        axios.get(WebLink+"/Videos",{headers:{token:token}} ).then(function (response) {
            //  if(response.data.status == 403) window.location = "/";
            console.log(response);
            let link =  response.data[0].VideoLink
            setVideo( link)
            
            return response.data
          })
          .catch(function (error) {
            
            console.log(error)
          })
        
    },[]);
    return (
        <div className="custom">
            <div className="container345" id='videos_watch'>
            <iframe width="100%" height="500"  src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div>
                <h4>To watch more videos, visit <a href="https://www.youtube.com/channel/UCQsdCu7_y1Awa2SgrDErACw" target="_blank"> Entira Clinic </a>  Youtube Channel </h4>
            </div>
            </div>
        </div>
    )
}

export default Videos
