import React from 'react';
import {Link } from 'react-router-dom';
import {FaqData } from './FAQData'

const FAQs = () => {
    return (
        <div className="panel-group" id="accordion-faqs">
            {
                FaqData.map((item,index)=>{
                    return (
                        <div className="panel" key={index} >
                            <div className="panel-heading">
                                <h5 className="panel-title">
                                    <Link data-toggle="collapse" data-parent="#accordion-faqs" to={item.PanelLink}>
                                        <i className={item.IconClass}></i> {item.Question}
                                    </Link>
                                </h5>
                            </div>
                            <div id={item.Id} className={index===0 ? "panel-collapse collapse in":"panel-collapse collapse" }>
                                <div className="panel-body">
                                    <p>
                                        {item.Answer}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
        </div>
    )
}

export default FAQs
