import React from 'react';
import {Link } from 'react-router-dom';
const ContactUs = () => {
    return (
        <section className="featured-doctors">
            <div className="container">
                <h2>Contact <span className="lite">With Us</span> </h2>
                <div className="row">
                {/* Time */}
                <div className="col-md-4 col-sm-6 col-xs-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <div className="bio-box">
                    <div className="inner">
                        <i className="far fa-clock contact-icon" title="Hours of operation"></i>
							{/* <h4>Hours of Operation</h4> */}
							<p className="designation"></p>
							<p className="divider"><i className="fa fa-plus-square"></i></p>
                                <p> 8:00 AM – 5:00 PM Monday thru Friday</p>
                                <p> Noon - 1:00 PM Closed </p>
                            <div style={{paddingBottom:"100px"}}></div>
						</div>
                    </div>
                </div>
                {/* Time End */} 
                {/* Location */}
                <div className="col-md-4 col-sm-6 col-xs-6 wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".2s">
                    <div className="bio-box">
                    <div className="inner">
                    <i className="fas fa-map-marker-alt contact-icon " title="Location"></i>
							<p className="designation"></p>
							<p className="divider"><i className="fa fa-plus-square" ></i></p>
							<span className="pointer-cursor" onClick={()=>window.open("http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=1807+W.+Frank,+Suite+100,+Lufkin,+TX+75904&sll=37.0625,-95.677068&sspn=49.844639,69.082031&ie=UTF8&z=17&iwloc=A")} >1807 W. Frank, Suite 100, Lufkin, TX 75904</span>
                                
                                <p> <span>Phone Number :</span> 936-634-1400</p>
                                <p> <span>Website :</span> <span onClick={()=>window.open("http://www.entiraclinic.com")} className="pointer-cursor">www.entiraclinic.com</span></p>
                            <div style={{paddingBottom:"80px"}}></div>
						</div>
                    </div>
                </div>
                {/* Location End */}
                
                {/* Headquarters */}
                <div className="col-md-4 col-sm-6 col-xs-6 wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".8s">
                    <div className="bio-box">
                    <div className="inner">
                    <i className="fa fa-building contact-icon" title="Headquarters"  ></i>
                            
							{/* <h4>Headquarters</h4> */}
							<p className="designation"></p>
							<p className="divider"><i className="fa fa-plus-square"></i></p>
                            <p style={{color:"#0B4575",fontSize:"15px"}}>Headquarter</p>
							<p>Entira Medical Clinics
                                14800 Quorum Drive, Suite 540
                                Dallas, TX 75254</p>
                                <p> <span>Email :</span> <span onClick={()=>window.open("mailto:info@entiraclinic.com")} className="pointer-cursor">info@entiraclinic.com</span></p>
                                <p> <span>Website :</span> <span onClick={()=>window.open("http://www.entiraclinic.com")} className="pointer-cursor">www.entiraclinic.com</span></p>
                            <div style={{paddingBottom:"10px"}}></div>
						</div>
                    </div>
                </div>
                {/* Hq End */}
                </div>
            </div>
        </section>
    )
}

export default ContactUs
