import React from 'react';
import './Features.css'

import {FeatureData } from './FeaturesData';

const Features = () => {
    return (
        <>
        <div className="notification-boxes row justify-content-md-center" >
            {FeatureData.map((item,index)=>{
                // if(index<4){
                    return (
                        <div className="flip-card col-md-3 col-sm-6 col-xs-6 " key={index}  >
                            <div className="box wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s" style={{height:'230px'}}>
                                
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <i className={item.IconClass+ " "+ "featuresInfo"}></i>
                                            <h4 className='featuresInfo' style={{color:"white",marginTop:"10px"}}>{item.Title}</h4>
                                            <p> </p>
                                            
                                        </div>
                                    <div className="flip-card-back">
                                        {item.Title == "Women's Health Care"? 
                                            <>
                                                <h6 style={{marginTop:"-20px"}}>{item.Title}</h6> 
                                                <p style={{fontSize:"13px"}}>{item.Description}</p>
                                            </>
                                            :
                                            <>
                                                <h5>{item.Title}</h5> 
                                                <p>{item.Description}</p>
                                            </>
                                        }
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    )
                // }
                
            })}

        </div>

        {/* <div className="notification-boxes row ">
            {FeatureData.map((item,index)=>{
                if(index>3){
                    return (
                        <div className="col-md-3 col-sm-6 col-xs-6 fd_col" key={index}  >
                            <div className="box wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s" style={{height:'230px'}}>
                                <i className={item.IconClass}></i>
                                <h4 style={{color:"white",marginTop:"10px"}}>{item.Title}</h4>
                                <p>{item.Description}</p>
                                
                            </div>
                        </div>
                    )
                }
                
            })}

        </div> */}
        {/* <div className="notification-boxes row fe-col black-css" >
            {FeatureData.map((item,index)=>{
                if(index < 4)
                return (
                    <div className="column col-size-features col-centered" key={index} >
                        <div className="box wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s" style={{height:'230px'}}>
                            <i className={item.IconClass}></i>
                            <h4 style={{color:"white",marginTop:"10px"}}>{item.Title}</h4>
                            <p>{item.Description}</p>
                            
                        </div>
                    </div>
                )
            })}

        </div> */}

        {/* <div className="notification-boxes row ">

            

                <div className="column col-size-features sized_col"  >
                        <div className="box wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s" style={{height:'230px'}}>
                            <i className="fa fa-flask"></i>
                            <h4 style={{color:"white",marginTop:"10px"}}>Lab Studies And Tests</h4>
                            <p></p>
                            
                        </div>
                </div>

                <div className="column col-size-features sized_col"  >
                        <div className="box wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s" style={{height:'230px'}}>
                            <i className="fa fa-flask"></i>
                            <h4 style={{color:"white",marginTop:"10px"}}>Lab Studies And Tests</h4>
                            <p></p>
                            
                        </div>
                </div>

        </div> */}
        </>
    )
}

export default Features
