import React from 'react'
import { SliderData } from './SliderData';
import { Link } from 'react-router-dom';

const Slider = () => {
    return (
        <>
        <div className="slogan"> <h4 className="text-center " style={{color:"white",paddingTop:"3px"}}> Workplace Wellness With Community Spirit ™ </h4> </div>
        <section className="slider clearfix">
            <div id="camera_wrap_1" className="camera_wrap camera_white_skin">
                {SliderData.map((item,index)=>{
                    return (
                        <div data-src={process.env.PUBLIC_URL + item.DataSrc} key={index}>
                            <div className="camera_caption fadeIn hidden-sm hidden-xs">
                                <h2>{item.MainHeading}</h2>
                                <h2>{item.Caption} </h2>
                                <p>{item.Description}</p>
                                {item.ExtendLink ? (<Link to={item.ExtendLink} className="btn btn-secondary" > Read More </Link>) : null}
                                
                            </div>
                        </div>
                    )
                })}
            </div>
      </section>
      </>
    )
}

export default Slider
