import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Modal, Button, DatePicker,Input } from 'antd';
import 'antd/dist/antd.css';
import './Footer.css';
import './Modal.css';
import Appointment from './Appointment';


const year = (new Date()).getFullYear();
const Footer = () => {
    
    return (
       <>
        <footer className="main-footer">
                    <div className="copyright">
                        <div className="container clearfix">
                            <p className="pull-left">
                                &copy; {year}  AlL Rights Reserved, <Link to="/"> <span>Entira Clinics</span></Link> 
                            </p>
                        </div>
                    </div>
	    </footer>
        <Appointment/>

       </>
    )
}

export default Footer
