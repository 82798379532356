exports.FaqData = [
    {
        PanelLink:"#collapse1",
        Id:"collapse1",
        IconClass:"fa fa-stethoscope",
        Question:"Who can use the clinic?",
        Answer:`Employees and dependents (age 8 and older) who participate in the Brookshire Brothers, Inc. Health Care Plan may utilize the clinic services.`

    },
    {
        PanelLink:"#collapse2",
        Id:"collapse2",
        IconClass:"fa fa-stethoscope",
        Question:"How does wellness and preventative care fit so prominently into Entira's approach?",
        Answer:` There is plenty of material and research acknowledging how wellness and preventative care 
        contributes to a better lifestyle and more productive people. To our knowledge there are no other workplace clinics that have made 
        this the focal point of their service delivery, and we believe this approach is the truest and most fundamental way to foster great medical care.`

    },
    {
        PanelLink:"#collapse3",
        Id:"collapse3",
        IconClass:"fa fa-stethoscope",
        Question:"What type of a waiting time should I expect?",
        Answer:`With a scheduled appointment time, the expected waiting time would be minimal. Walk-in visitors will be seen as quickly as possible following patients with appointments.`

    },
    {
        PanelLink:"#collapse4",
        Id:"collapse4",
        IconClass:"fa fa-stethoscope",
        Question:"How does my health record remain private?",
        Answer:`The clinic is a separate entity from the employer. Privacy of patient records is maintained according to the requirements of federal HIPAA regulations.`

    },
    {
        PanelLink:"#collapse5",
        Id:"collapse5",
        IconClass:"fa fa-stethoscope",
        Question:"What is my out of pocket expense when I use the clinic?",
        Answer:`Each visit to the clinic will require a $10.00 co-payment per patient. Lab services related to the clinic
         visit are included in the co-payment. Any prescriptions or studies outside of the clinic visit are covered under the health plan – out of pocket expense will vary. 
        The clinic visit does not count against the two annual $35.00 co-payment physician visits offered through the health plan.`

    },
    
]