import React from 'react'
import ContactUs from '../ContactUs/ContactUs'
import AllDoctors from '../Doctors/AllDoctors/AllDoctors'
import Doctors from '../Doctors/Doctors'
import FAQs from '../FAQs/FAQs'
import HowWeWorks from '../HowWeWorks/HowWeWorks'
import News from '../News/News'
import Videos from '../Videos/Videos'
import Services from '../Services/Services'
import Details from './Details/Details'
import Features from './Features/Features'

const Home = () => {
    return (
        <>
            {/* <div className="slogan"> <h4 className="text-center "> Workplace Wellness With Community Spirit ™ </h4> </div> */}
            {/* welcome section  */}
            <div className="home-div" id="Home">
                {/* <Features /> */}
                <Details />
            </div>
            {/* How we Works  */}
            <div id="HowWeWorks"></div>
            <HowWeWorks />

            <div className="container main-container" id="Videos">
            <h3 className="main-heading2 nomargin-top"><i className="fa fa-pencil-square-o"></i> Videos</h3>
                <Videos/>
            </div>

             {/* Doctors */}
             <div className="container main-container" id="Team">
                <h3 className="main-heading2 nomargin-top"><i className="fa fa-pencil-square-o"></i> Team</h3>
                {/* <Doctors /> */}
                <AllDoctors />
            </div>

           

            {/* FAQs  */}
            <div className="container main-container" id="Faqs">
                <h3 className="main-heading2 nomargin-top"><i className="fa fa-pencil-square-o"></i> Frequently Asked Questions</h3>
                <FAQs />
            </div>

            {/* Healthy Resources  */}
            {/* <div className="container main-container" id="Resources">
               
                <Services />
            </div> */}
            
            {/* Announcements */}
            <div id="News"></div>
            <div className="container main-container" >
                <h3 className="main-heading2 nomargin-top"><i className="fa fa-pencil-square-o"></i> Announcements</h3>
                <News />
            </div>
		    
            {/* contact us  */}
            <div id="Contact"></div>
            <ContactUs />
        </>
        
    )
}

export default Home
