exports.TabContentData = [
    {
        Id:"tab-1",
        TabContent:[
            {
                Title:"Cooking Innovations",
                Link:"http://www.memorialhealth.us/cooking",
                Description: "Healthy Cooking Innovations offered by Memorial Health Systems of East Texas"
            },
            {
                Title:"Diabetes Awareness",
                Link:"http://www.cdc.gov/features/livingwithdiabetes/",
                Description: "Diabetes Awareness Tips"
            },
            {
                Title:"Grocery List Builder",
                Link:"http://checkmark.heart.org/",
                Description: "Grocery List Builder — How to Create Your List of Certified Heart Healthy Foods"
            }
        ]
    },
    {
        Id:"tab-2",
        TabContent:[
            {
                Title:"Driving Safely",
                Link:"http://www.trafficsafety.org/",
                Description: "A Healthy Road for Driving Safely"
            },
            {
                Title:"Health Awareness",
                Link:"http://www.ahrq.gov/patients-consumers/patient-involvement/healthy-men/quiz.html",
                Description: "Take the Healthy Men Quiz and Test Your Preventative Health Awareness"
            },
            {
                Title:"Against Arthritis",
                Link:"http://www.fightarthritispain.org/",
                Description: "My Weapon Against Arthritis — Moving is the Best Medicine"
            },
            {
                Title:"For Women",
                Link:"http://www.youtube.com/OfficialGoRed4Women",
                Description: "Go Red For Women ™ video: 'Just a Little Heart Attack'"
            }
        ]
    },
    {
        Id:"tab-3",
        TabContent:[
            {
                Title:"Adherence Improvement",
                Link:"http://www.entiraclinic.com/pdfs/Med_adherence_influencing_factors.pdf",
                Description: "New Landscape of Medical Adherence Improvement"
            },
            {
                Title:"Medication Adherence",
                Link:"http://www.entiraclinic.com/pdfs/New_landscape_of_med_adherence_improvement.pdf",
                Description: "EHR–Based Strategy to Promote Medication Adherence"
            },
            {
                Title:"Cholesterol",
                Link:"http://www.entiraclinic.com/pdfs/Cholesterol%20Need%20to%20Know.pdf",
                Description: "Cholesterol: What You Need to Know"
            },
            // {
            //     Title:"Research #4",
            //     Link:"http://www.entiraclinic.com/pdfs/cholesterol_atglance.pdf",
            //     Description: "Cholesterol, at a Glance"
            // },
            {
                Title:"Silent Health Crisis",
                Link:"http://www.entiraclinic.com/pdfs/silenthealthcrisis.pdf",
                Description: `The Silent Health Crisis" — What Men Don't Know`
            }
        ]
    },
    {
        Id:"tab-4",
        TabContent:[
            {
                Title:"Healthy Aging",
                Link:"http://www.healthyaging.net/",
                Description: "Tips to Consider for Healthy Aging"
            },
            {
                Title:"How To Stay Healthy",
                Link:"http://www.lhj.com/health/fitness/to-stay-healthy-keep-moving/",
                Description: "To Stay Healthy, Keep Moving! Article from Ladies Home Journal"
            }
        ]
    },
]