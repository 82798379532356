import React,{useState} from 'react';
import {Link } from 'react-router-dom';
import { NavMenus } from './NavbarData';

const Navbar = () => {

    const [click,setClick] = useState(false);

    return (
        <nav id="nav" className={click ? "navbar navbar-default navbar-static-top affix" : "navbar navbar-default navbar-static-top affix-top"}>
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle" data-toggle="collapse" datatarget=".navbar-collapse" onClick={()=> setClick(!click)} >
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<Link to="/" className="navbar-brand">
							<img src={process.env.PUBLIC_URL+'assets/images/icons/EntiraLogo.jpg'} className="logo" alt="Logo" style={{width:'130px',height:'40px',marginTop:'-4px',marginLeft:"-10px"}} />
                            <span> LUFKIN</span>
						</Link>
					</div>
					
                    <div className={click ? "navbar-collapse collapse in cream": "navbar-collapse collapse"} aria-expanded={click} style={click ? {height:'auto'} : {height:'1px'}}>
                        <ul className="nav navbar-nav navbar-right">
                            {NavMenus.map((item,index)=>{
                                    return ( <li className={item.Class ? item.Class : ''} key={index +"b"} onClick={()=> setClick(false)} >
                                    {item.isDropDown ?  
                                        <>
                                            <Link to="#" className={item.LinkClass} data-toggle={item.DataToggle}>{item.Title}</Link>
                                            <ul className={item.DropdownClass} role={item.Role}>
                                                {item.DropdownMenus.map((menu,id)=>{
                                                    return (
                                                        <li key={id+"a"}><Link to={menu.Link}>{menu.Title}</Link></li>
                                                    )
                                                })}
                                                
                                            </ul>
                                        </>
                                    :<a href={item.Link} > {item.Title} </a>
                                   }</li> )
                                }
                                
                               
                            )}
                        </ul>
                    </div>
                </div>
          </nav>
    )
}

export default Navbar
