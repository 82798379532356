exports.TabTitlesData = [
    {
        ImgSrc:"assets/images/icons/diet-3.png",
        Title:"DIETARY",
        Href:"#tab-1",
        AriaControls:"tab-1"
    },
    {
        ImgSrc:"assets/images/icons/wellness-1.png",
        Title:"WELLNESS",
        Href:"#tab-2",
        AriaControls:"tab-2"
    },
    {
        ImgSrc:"assets/images/icons/research-1.png",
        Title:"RESEARCH",
        Href:"#tab-3",
        AriaControls:"tab-3"
    },
    {
        ImgSrc:"assets/images/icons/running-1.png",
        Title:"FITNESS",
        Href:"#tab-4",
        AriaControls:"tab-4"
    },
    
]