import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import {WebLink} from '../../../../Export/Links';
import './Modal.css'

const CustomModal = ({title,item}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button type="primary" className="btn btn-secondary" style={{position: "absolute",bottom: "25px",marginLeft:"-50px"}} onClick={showModal}>
        <i className="fa fa-arrow-circle-right"></i>Read More
      </button>
      <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} style={{minWidth:'90%'}} footer={null}>
          
        <div className="container main-container" style={{padding:"0",margin:'0'}}>
            <div className="row col_margin">
                <div className="col-md-11 col-sm-12 col-xs-12 " >

                <div className="news-post-single" style={{minWidth:'80%'}}>
                    <article className="news-post">
                    <img src={WebLink+"/Image/"+item.ImageFileName} alt="Single Department" className="img-responsive img-center-sm img-center-xs img_margin12" style={{width:'500px',height:'500px'}} />
                        
                        <div className="inner">
                                    <h4>{item.DoctorName}, {item.Qualification}</h4>
                                    <h6><i>{item.Designation}</i></h6>
                                    <div className="news-post-content doctor-info" >
                                        <p>{item.ParaOne}</p>
                                        <p>{item.ParaTwo}</p>
                                        <blockquote>
                                            <p>{item.ParaThree}</p>
                                        </blockquote>
                                        <p>{item.ParaFour}</p>
                                        
                                    </div>
                                </div>
                    </article>
                </div>
                </div>
            </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomModal
