import React from 'react';
import Navbar from './Navbar/Navbar';
import Topbar from './Topbar/Topbar';

const TopSection = () => {
    return (
        <header className="main-header">
            <div className="container container-relative">
                <Topbar/>
                <Navbar />
            </div>
        </header>
       
    )
}

export default TopSection
