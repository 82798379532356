import React from 'react'

const HowWeWorks = () => {
    return (
        <section className="featured-doctors">
            <div className="container">
                <h2><span className="lite">How We</span> Work</h2>
                <p>
                    At Entira Clinics, we strive to be flexible to your work schedule and give you our undivided attention. 
                    We request that you make a personal appointment, as we do not accept walk-in patients unless you have an emergency.  
                    Simply call us at 936-634-1400.
                    
                </p>
                <p className="white-color">
                    On all your visits, please bring:
                </p>
                    {/* <ul className="white-color">
                        <li className="hide-bullet"><h5 className="text-border" ><span> Your affordable $10 co-pay.  It can be paid by credit card, cash or check.</span> </h5></li>
                        <li className="hide-bullet"><h5 className="text-border" ><span> Your employee or photo ID to verify your eligibility in the medical plan.</span> </h5></li>
                    </ul> */}

                    <div className="Hnotification-boxes row ImpoCall">
                        {/* {FeatureData.map((item,index)=>{ */}
                            {/* return ( */}
                                <div className="col-md-6 col-sm-6 col-xs-6" >
                                    <div className="Hbox wow fadeInLeft" data-wow-duration=".6s" data-wow-delay=".3s">
                                        <i className="fa fa-dollar-sign worksIcon"></i>
                                        
                                        <hr />
                                        <h4>Your affordable $10 co-pay.  It can be paid by credit card, cash or check.</h4>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6" >
                                    <div className="Hbox wow fadeInLeft" data-wow-duration=".6s" data-wow-delay=".3s">
                                        <i className="far fa-id-card worksIcon"></i>
                                        <hr />
                                        <h4>Your coverage ID to verify your eligibility in the medical plan.</h4>
                                        
                                    </div>
                                </div>
                            {/* ) */}
                        {/* // })} */}
                        
                    </div>
                <hr/>
                <p className="white-color">
                    Be sure to arrive a few minutes early on your first visit to Entira so that you can provide a complete medical history for our records.
                </p>
                <p className="white-color">
                    We look forward to keeping you healthy at work, and beyond.
                </p>
            </div>
        </section>
    )
}

export default HowWeWorks;
