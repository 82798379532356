import React,{useState} from 'react' ;
import "./MeetingForm.css" ;
import { Modal, Button, DatePicker,Input,Select } from 'antd';

const MeetingForm = () => {
    const [items, setItems ] = useState("");
    const [errors, setErrors ] = useState("");

    const handleInputChange = (event) => {
        event.persist();
        // console.log("event",event)
        setItems(items => ({...items, [event.target.name]: event.target.value}));
    }

  return (
    <div className="container">
          <form autoComplete="off" style={{textAlign:"center"}}>
                <div className='form-div'>
                    <p className='form-title'>Name:</p>
                    <Input className="form-input" type='text' placeholder="My name" onInput={handleInputChange}  name='name' value={items.name} />
                    <p className='form-error'></p>
                </div>
                <div className='form-div'>
                    <p className='form-title'>E-Mail:</p>
                    <Input className="form-input" type='text' placeholder="email@email.com" onInput={handleInputChange}  name='email' value={items.name} />
                    <p className='form-error'></p>
                </div>
                <div className='form-div'>
                    <p className='form-title'>Phone:</p>
                    <Input className="form-input" type='text' max="12" placeholder="123456789" onInput={handleInputChange}  name='phone' value={items.name} />
                    <p className='form-error'></p>
                </div>
                <div className='form-div'>
                    <p className='form-title'>Meeting mode:</p>
                    <Input className="form-input" style={{width:"40px"}} type='radio' onInput={handleInputChange}  name='mode' value="Offline" /><span>Offline</span>
                    <Input className="form-input" style={{width:"40px",marginLeft:"40px"}} type='radio' onInput={handleInputChange}  name='mode' value="Online" /><span>online</span>
                    <p className='form-error'></p>
                </div>

                                                

                                                

                    <div className='form-div'>
                    <button className="submit-btn" type='submit'  title="Make an appointment">Submit Request</button>
                    </div>
                </form>
    </div>
  )
}

export default MeetingForm