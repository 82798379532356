exports.NewsData = [
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #1",
        section:"Health",
        date:"12/16/2020",
        Image:'assets/images/doctor-profile-2.jpg'
    },
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #2",
        section:"Heart",
        date:"12/16/2020",
        Image:'assets/images/doctor-profile-2.jpg'
    },
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #3",
        section:"Eye",
        date:"12/16/2020",
        Image:'assets/images/doctor-profile-2.jpg'
    },
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #4",
        section:"Blood",
        date:"12/16/2434",
        Image:'assets/images/doctor-profile-2.jpg'
    },
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #5",
        section:"Tumor",
        date:"12/16/2020",
        Image:'assets/images/doctor-profile-2.jpg'
    },
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #6",
        section:"Yoga",
        date:"12/16/8762",
        Image:'assets/images/doctor-profile-2.jpg'
    },
    {
        Heading:"Volunteers Help Bring Hyperfast Broadband to Village #7",
        section:"Drug",
        date:"12/16/9898",
        Image:'assets/images/doctor-profile-2.jpg'
    }
]