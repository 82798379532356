// Do not change any class without confirmation


exports.SliderData = [
    
    {
        MainHeading:"Our Team",
        // Caption:"Some caption Here",
        Description:"Dedicated to your health!",
        ExtendLink:"",
        DataSrc:"assets/images/slider/Entira-team_1.jpg"
    },
    {
        MainHeading:"Our Promises",
        // Caption:"Some caption Here",
        Description:"Entira works to keep you well, with a full range of vital services. ",
        ExtendLink:"",
        DataSrc:"assets/images/slider/medicalKits.jpg"
    },
    {
        MainHeading:"Personal Attention",
        // Caption:"Some caption Here",
        Description:"Medical attention from the community’s finest professionals.",
        ExtendLink:"",
        DataSrc:"assets/images/slider/slider-04.jpg"
    }
    // {
    //     MainHeading:"Main Heading",
    //     Caption:"Some caption Here",
    //     Description:"Lorem Ipsum is simply dummy text printer took a galley of type and scrambled it to make a type specimen book ...",
    //     ExtendLink:"/services",
    //     DataSrc:"assets/images/slider/slider-image-3.png"
    // }
]