import React from 'react'

const Topbar = () => {
    return (
        <div className="top-bar hidden-sm hidden-xs">
            <div className="row ">
                <div className="col-sm-13 col-xs-12 ">
                    <span className="top-info"><i className="fa fa-phone"></i></span> 936-634-1400
                    <span className="top-info"><i className="fas fa-envelope"></i></span><span onClick={()=>window.open("mailto:info@entiraclinic.com")} className="pointer-cursor">info@entiraclinic.com</span>
                    <span className="top-info"><i className="fa fa-map-marker"></i></span><span className="pointer-cursor" onClick={()=>window.open("http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=1807+W.+Frank,+Suite+100,+Lufkin,+TX+75904&sll=37.0625,-95.677068&sspn=49.844639,69.082031&ie=UTF8&z=17&iwloc=A")} >1807 W. Frank, Suite 100, Lufkin, TX 75904</span>
                    <span className="top-info"><i className="fa fa-calendar"></i></span>8 AM–5 PM Mon Thu Fri
                    <span className="top-info"><a title='youtube videos' style={{color:'#0B4575'}} href="https://www.youtube.com/channel/UCQsdCu7_y1Awa2SgrDErACw" target="_blank"> <i className="fa fa-play"></i> Videos  </a></span>
                </div>
            {/* <div className="col-xs-12" style={{color:"#666"}}>
                <ul className="list-unstyled list-inline">
                    <li><a href="#"><i style={{color:"#000000"}} className="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i style={{color:"#666"}} className="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i style={{color:"#666"}} className="fa fa-instagram"></i></a></li>
                    <li><a href="#"><i style={{color:"#666"}} className="fa fa-vimeo"></i></a></li>
                </ul>
            </div> */}
        </div>
     </div>
     
    )
}

export default Topbar
