
// Do not change any class without confirmation

exports.NavMenus = [
    {
        isDropDown : false,
        Class:"active",
        Title:"Home",
        Link: "/#Home"
    },
    {
        isDropDown : false,
        Class:"",
        Title:"How We Work",
        Link: "/#HowWeWorks"
    },
    {
        isDropDown : false,
        Class:"",
        Title:"Videos",
        Link: "/#Videos"
    },
    {
        isDropDown : false,
        Class:"",
        Title:"Team",
        Link: "/#Team"
    },
    
    {
        isDropDown : false,
        Class:"",
        Title:"FAQs",
        Link: "/#Faqs"
    },
    {
        isDropDown : false,
        Class:"",
        Title:"Announcements",
        Link: "/#News"
    },
    {
        isDropDown : false,
        Class:"",
        Title:"Contact Us",
        Link: "/#Contact"
    }
    
    // {
    //     isDropDown : true,
    //     Class:"dropdown",
    //     LinkClass:"dropdown-toggle",
    //     DataToggle:"dropdown",
    //     Role:"menu",
    //     DropdownClass:"dropdown-menu",
    //     Title:"About Us",
    //     Link: "/about-us",
    //     DropdownMenus:[
    //         {Title:"Services",Link:"/services"},
    //         {Title:"Single Services",Link:"/single-services"}
    //     ]
    // }

] 